<template>
  <div>
    <!-- form -->
    <b-form
      style="max-width: 500px"
      class="ml-1 mt-1 mr-1 mb-1"
    >
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
            label="Stream Title"
            label-for="bio-area"
          >
            <b-form-input
              id="title"
              v-model="form.title"
              rows="1"
              placeholder="owning Puppet in Valorant!"
              maxlength="80"
            />
          </b-form-group>
          <b-form-group
            label="Category"
            label-for="bio-area2"
          >
            <AutoSuggestAjax @childToParent="onChildClick" />
          </b-form-group>
        </b-col>
        <!--/ bio -->
        <!--/ website -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mt-1 mr-1"
            @click.prevent="submitForm"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AutoSuggestAjax from './../Core/CreatorStudio/Comps/Test.vue'

export default {
  components: {
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    AutoSuggestAjax,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fromChild: '',
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      onboardIframe: '',
      form: {
        title: '',
        category: '',
        enableVods: true,
      },
      stream: {
        server: 'Check #dev-updates in the Discord',
        streamKey: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    if (this.$auth.user) {
      const channelData = await this.channelLookupBySlug(this.$auth.user.nickname)
      this.form.title = channelData.stream.title
      this.form.category = channelData.stream.category_name
    }
  },
  /* eslint-disable */
  methods: {
    resetStreamKey(){
  this.$bvToast.toast("THIS HAS NOT BEEN ENABLED YET. SOON™", {
  title: 'Stream Key Reset',
   variant: 'danger',
   solid: true,
  })
    },
    onCopy() {
  this.$bvToast.toast("Stream key copied to clipboard.", {
  title: 'Stream Key',
   variant: 'success',
   solid: true,
  })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
    onChildClick (value) {
      this.fromChild = value
    },
    makeToast(message) {
      this.$bvToast.toast(message, {
        title: 'Error uploading image.',
        variant: 'danger',
        solid: true,
      })
    },
     async submitForm() {
        
        const streamUpdate = await this.channelUpdateStream(this.form.title, this.fromChild)
        if (streamUpdate.success) {
         this.$bvToast.toast("Title and Category updated successfully.", {
            title: 'Success',
            variant: 'success',
            solid: true,
        })
        } else {
             this.$bvToast.toast(message, {
                title: 'Error.',
                variant: 'danger',
                solid: true,
            })
        }
    },
        async vodToggle() {
      let config = {
  headers: {
    Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
  }
}
      axios.post('https://api-staging.brimelive.com/internal/channel/update?client_id=605e4d6b3d79e12e5437985b', {
    vodsEnabled: this.form.enableVods,
  }, config)
 .then((res) => {
    this.$bvToast.toast("Vod Settings Updated", {
  title: 'Success',
   variant: 'success',
   solid: true,
  })})
  .catch(function (errors) {
  const message = errors[0].split(":")[1]
  this.$bvToast.toast(message, {
    title: 'Error.',
    variant: 'danger',
    solid: true,
  })
  });
    },
    resetForm() {
      this.form = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>